<template>
    <div>
        <v-container>
            <v-row justify="end">
                <v-col>
                    <v-btn color="green" @click="openComposeDialog">New Message</v-btn>
                </v-col>
                <v-col>
                    <v-btn @click="fetchMessages">
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="12" lg="3" class="pt-1 pb-2">
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        solo
                        hide-details
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-container>

        <v-data-table
            :headers="headers"
            :items="messages"
            :loading="loading"
            :options.sync="options"
            :search="search"
            :footer-props="{itemsPerPageOptions: [10, 20, 30, 40, 50]}"
            :server-items-length="total"
            sort-by="date"
            sort-desc
            must-sort
            :show-select="showSelect"
        >
            <template v-slot:body="{ items, isSelected, select}">
                <tbody>
                <tr v-for="item in items" :key="item.id">
                    <td v-if="showSelect">
                        <v-checkbox
                            :input-value="isSelected(item)"
                            hide-details
                            color="white"
                            @click="select(item, !isSelected(item))"
                            class="ma-0 pl-4"
                        >
                        </v-checkbox>
                    </td>
                    <td v-if="showStar">
                        <v-rating
                            xv-model="item.starred"
                            clearable
                            length="1"
                            color="yellow"
                            background-color="white"
                            title="Star Message"
                        >
                        </v-rating>
                    </td>
                    <td>
                        <div v-if="item.from && item.from_username">
                            <router-link :to="'/user/' + item.from_username" class="no-underline color-inherit">
                                <UserAvatarList
                                    :user="{ID: item.from, Username: item.from_username, Callsign: item.from_callsign}"
                                    xsmall
                                />
                            </router-link>

                            <router-link :to="'/user/' + item.from_username" class="no-underline color-inherit">
                                <span class="truncate">{{ item.from_username }}</span>
                            </router-link>&nbsp;
                            <small>
                                <router-link :to="'/license/' + item.from_callsign"
                                             class="no-underline color-inherit">
                                    {{ item.from_callsign }}
                                </router-link>
                            </small>
                        </div>
                    </td>
                    <td @click="openMessageDialog(item)">{{ formatDate(item.date) }}</td>
                    <td class="truncate" @click="openMessageDialog(item)">{{ item.subject }}</td>
                    <td class="truncate" @click="openMessageDialog(item)">{{ item.body }}</td>
                    <td class="text-right">
                        <v-btn color="rfaccent2" small v-if="item.type === 'repeater'" @click="openRequestResponseDialog">Respond</v-btn>
<!--                        <v-btn small icon dark class="mr-2 success" title="Mark as Read" v-if="item.type === 'message'">-->
<!--                            <v-icon small>mdi-check</v-icon>-->
<!--                        </v-btn>-->
                        <v-btn small icon dark class="mr-2 info" title="Reply" v-if="item.type === 'message'">
                            <v-icon small>mdi-reply</v-icon>
                        </v-btn>
<!--                        <v-btn small icon dark class="mr-2 info" title="Forward" v-if="item.type === 'message'">-->
<!--                            <v-icon small>mdi-share</v-icon>-->
<!--                        </v-btn>-->
                        <v-btn small icon dark class="mr-2 error" title="Delete" v-if="item.type === 'message'">
                            <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-data-table>

        <RequestResponse :request="responseRequest.request" />

        <v-dialog v-model="dialogs.compose.state" persistent attach="#mainContent" max-width="900">
            <v-card>
                <v-toolbar dense flat color="rfaccent2">
                    <v-toolbar-title>Compose Message</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="confirmDiscard">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="mt-5">
                    <v-text-field v-model="dialogs.compose.subject" label="Subject" outlined></v-text-field>

                    <v-textarea v-model="dialogs.compose.body" label="Subject" outlined></v-textarea>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="error" @click="confirmDiscard">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="sendMessage">Send
                        <v-icon class="ml-1" small>mdi-send</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogs.message.state" persistentx attach="#mainContent" max-width="900"
                  @click:outside="closeMessageDialog">
            <v-card>
                <v-toolbar dense flat color="rfaccent2">
                    <v-toolbar-title>{{ dialogs.message.subject }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeMessageDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="mt-5">
                    {{ dialogs.message.body }}
                </v-card-text>
                <v-card-actions>

                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<style>
    .truncate {
        max-width: 1px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>

<script>
    import moment from 'moment';
    import UserAvatarList from '@/components/UserAvatarList.vue';
    import RequestResponse from '@/components/Profile/RequestResponse.vue';
    import axios from "axios";
    import config from "@/config";

    export default {
        name: 'Inbox',

        props: ['user'],

        components: {
            UserAvatarList,
            RequestResponse
        },

        data: () => ({
            search: null,
            loading: false,
            options: {},
            headers: [
                {text: 'From', value: 'from_username', width: 300, sortable: true},
                {text: 'Date', value: 'date', width: 200},
                {text: 'Subject', value: 'subject', sortable: false, width: 300},
                {text: 'Message', value: 'body', sortable: false},
                {text: '', value: 'actions', sortable: false, width: 175},
            ],
            showSelect: false, //@todo enable row selection
            showStar: false,
            dialogs: {
                compose: {
                    state: false,
                    to: null,
                    subject: null,
                    body: null,
                },
                message: {
                    state: false,
                    from: null,
                    date: null,
                    subject: null,
                    body: null,
                }
            },
            total: 0,
            messages: [],
            responseRequest: {
                request: 123
            },
        }),

        watch: {
            options: {
                async handler() {
                    await this.fetchMessages();
                },
                deep: true,
            },

            async search() {
                await this.fetchMessages();
            },
        },

        methods: {
            formatDate(date) {
                return moment(date).format('MM/DD/YYYY h:mm a');
            },

            async fetchMessages() {
                try {
                    let axiosHeaders = {};
                    if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                    this.loading = true;
                    const offset = ((this.options.page - 1) * this.options.itemsPerPage);
                    const response = await axios.get(`${config.API_LOCATION}/messages?search=${this.search ? this.search : ''}&limit=${this.options.itemsPerPage}&skip=${offset}&sort=${this.options.sortBy}&descending=${this.options.sortDesc}`, {
                        headers: axiosHeaders
                    });
                    this.loading = false;

                    this.total = response.data.info.total;
                    this.messages = response.data.messages;
                } catch (err) {
                    console.error(err);
                }
            },

            openRequestResponseDialog(){
                this.responseRequest.request = {
                    test: 456
                };
            },

            openMessageDialog(message) {
                this.dialogs.message.from = message.from;
                this.dialogs.message.subject = message.subject;
                this.dialogs.message.body = message.body;
                this.dialogs.message.state = true;
            },

            closeMessageDialog() {
                this.dialogs.message.state = false;

                //clear form fields
                this.dialogs.message.from = null;
                this.dialogs.message.subject = null;
                this.dialogs.message.body = null;
            },

            openComposeDialog() {
                this.dialogs.compose.state = true;
            },

            closeComposeDialog() {
                this.dialogs.compose.state = false;

                //clear form fields
                this.dialogs.compose.to = null;
                this.dialogs.compose.subject = null;
                this.dialogs.compose.body = null;
            },

            confirmDiscard() {
                const confirm = window.confirm('Are you sure you want to discard this message? The draft will not be saved.');

                if (confirm) this.closeComposeDialog();
            },

            sendMessage() {
                window.alert('TODO: Send message to recipient');

                this.closeComposeDialog();
            }
        },

        mounted() {

        }
    }
</script>
