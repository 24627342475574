<template>
    <v-container>
        <AdBTWR v-if="!user.subscribed"/>

        <h1 class="page-header font-weight-light">Inbox</h1>

        <div v-if="user.authenticated" class="mb-15">

            <Inbox :user="user" />

            <Requests :user="user" v-if="1==2" />
        </div>

        <div v-else>
            <v-alert type="warning" class="mt-5" border="left" elevation="3" prominent>
                You must be logged in to view your messages.
            </v-alert>
        </div>

    </v-container>
</template>

<script>
    import { useTitle } from '@vueuse/core';
    import AdBTWR from '@/components/ads/BTWR.vue';
    import Inbox from '@/components/Profile/Inbox';
    import Requests from '@/components/Profile/Requests';

    export default {
        name: 'InboxPage',

        props: ['user'],

        components: { AdBTWR, Inbox, Requests },

        mounted() {
            useTitle('Inbox - myGMRS.com');
        }
    }
</script>
